// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@layer packages {
    .Z2MzjE17xlmYsX7F59jR {
        display: flex;
        flex-direction: column;
        position: relative;
        color: var(--colors-secondary);
        border-radius: var(--globals-baseUnit);

        &:focus-visible {
            outline: none;
        }
    }

    .KOw91lztUTnAi6PgDSgt {
        background: white;
    }

    .KOrgPGWpzswoMI_7VUnS {
        background: var(--colors-primary);
    }

    .oTJqYdNendkY9xV8gdC7 {
        background: var(--colors-success);
    }

    .syqVcPQEI9nxO7uxJsfC {
        background: var(--colors-info);
    }

    .NFZc4c_jgEV1LYFvlNAr {
        background: var(--colors-warning);
    }

    .CKN00nXq8nvsBIdHl1bd {
        background: var(--colors-secondary);
    }

    .D6VtEsqkJKC5CuNVYmS5 {
        padding: calc(var(--globals-baseUnit) * 2);
        display: flex;
        align-items: center;

        &:focus-visible {
            outline: none;
        }
    }

    .vt2U8qZkPfM9Ldjch1B4 {
        color: white;
    }
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/system/message/src/components/Message.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,kBAAkB;QAClB,8BAA8B;QAC9B,sCAAsC;;QAEtC;YACI,aAAa;QACjB;IACJ;;IAEA;QACI,iBAAiB;IACrB;;IAEA;QACI,iCAAiC;IACrC;;IAEA;QACI,iCAAiC;IACrC;;IAEA;QACI,8BAA8B;IAClC;;IAEA;QACI,iCAAiC;IACrC;;IAEA;QACI,mCAAmC;IACvC;;IAEA;QACI,0CAA0C;QAC1C,aAAa;QACb,mBAAmB;;QAEnB;YACI,aAAa;QACjB;IACJ;;IAEA;QACI,YAAY;IAChB;AACJ","sourcesContent":["@layer packages {\n    .message {\n        display: flex;\n        flex-direction: column;\n        position: relative;\n        color: var(--colors-secondary);\n        border-radius: var(--globals-baseUnit);\n\n        &:focus-visible {\n            outline: none;\n        }\n    }\n\n    .messageNeutral {\n        background: white;\n    }\n\n    .messageAction {\n        background: var(--colors-primary);\n    }\n\n    .messageSuccess {\n        background: var(--colors-success);\n    }\n\n    .messageInfo {\n        background: var(--colors-info);\n    }\n\n    .messageWarning {\n        background: var(--colors-warning);\n    }\n\n    .messageFailure {\n        background: var(--colors-secondary);\n    }\n\n    .messageContent {\n        padding: calc(var(--globals-baseUnit) * 2);\n        display: flex;\n        align-items: center;\n\n        &:focus-visible {\n            outline: none;\n        }\n    }\n\n    .messageContentFailure {\n        color: white;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export var message = `Z2MzjE17xlmYsX7F59jR`;
export var messageNeutral = `KOw91lztUTnAi6PgDSgt`;
export var messageAction = `KOrgPGWpzswoMI_7VUnS`;
export var messageSuccess = `oTJqYdNendkY9xV8gdC7`;
export var messageInfo = `syqVcPQEI9nxO7uxJsfC`;
export var messageWarning = `NFZc4c_jgEV1LYFvlNAr`;
export var messageFailure = `CKN00nXq8nvsBIdHl1bd`;
export var messageContent = `D6VtEsqkJKC5CuNVYmS5`;
export var messageContentFailure = `vt2U8qZkPfM9Ldjch1B4`;
export default ___CSS_LOADER_EXPORT___;
