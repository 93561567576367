// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OvW9dK0rRoWRGEcR5LwI {
    font-weight: 600;
}

.hvjwQq_8ZR5RoOwH2Ifz {
    font-size: 1.106rem;
    line-height: 1.2rem;
    font-weight: 700;
}

.kENlF2OSwbIAwQ8I1cxP {
    padding: 0 calc(0.5 * var(--globals-baseUnit));
    text-wrap: wrap;
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/navigation/button/src/components/ButtonText.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,8CAA8C;IAC9C,eAAe;AACnB","sourcesContent":[".buttonBannerPrimaryText {\n    font-weight: 600;\n}\n\n.buttonMapTooltipText {\n    font-size: 1.106rem;\n    line-height: 1.2rem;\n    font-weight: 700;\n}\n\n.buttonHighlightedText {\n    padding: 0 calc(0.5 * var(--globals-baseUnit));\n    text-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
export var buttonBannerPrimaryText = `OvW9dK0rRoWRGEcR5LwI`;
export var buttonMapTooltipText = `hvjwQq_8ZR5RoOwH2Ifz`;
export var buttonHighlightedText = `kENlF2OSwbIAwQ8I1cxP`;
export default ___CSS_LOADER_EXPORT___;
