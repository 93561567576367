// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@layer packages {
    .ZjXKsurEv6g61GyfEUTH :root {
        --input-width: 100%;
    }

    .ZjXKsurEv6g61GyfEUTH {
        display: flex;
        width: 100%;
        gap: calc(var(--globals-baseUnit) * 3);
    }

    .HkOOMY6VkJ3P8vMjycLk {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        padding: 0;
    }

    .RqzfrPCdp3k7V6KuikTR {
        opacity: 0.4;
    }

    .Y4F4rQG_n9UlDMXopZxN {
        flex: 0 0 120px;
        position: relative;
    }

    .kmbhFcdniqoFSDZvfcob {
        justify-content: center;
        flex: 0 0 auto;
    }

    .oVjoITkfsGTS9NuuT_e7 {
        text-align: right;
        width: var(--input-width);
        box-sizing: content-box;
    }

    .uzfK667bcBf6P5ZZAcOQ {
        margin-left: 0;
    }

    .NUBH0ecMTEsXCtpUvAww {
        color: var(--colors-gray-700);
    }

    .cT3d8sQm2HcAjbOdBvKj {
        visibility: hidden;
        display: inline-block;
        position: absolute;
        z-index: 0;
    }

    .BOkjqtsPmh0Wa2iYzGF0 {
        z-index: 2;
    }
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/forms/inputs/src/components/max_value_input/MaxValueInput.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,mBAAmB;IACvB;;IAEA;QACI,aAAa;QACb,WAAW;QACX,sCAAsC;IAC1C;;IAEA;QACI,cAAc;QACd,aAAa;QACb,mBAAmB;QACnB,UAAU;IACd;;IAEA;QACI,YAAY;IAChB;;IAEA;QACI,eAAe;QACf,kBAAkB;IACtB;;IAEA;QACI,uBAAuB;QACvB,cAAc;IAClB;;IAEA;QACI,iBAAiB;QACjB,yBAAyB;QACzB,uBAAuB;IAC3B;;IAEA;QACI,cAAc;IAClB;;IAEA;QACI,6BAA6B;IACjC;;IAEA;QACI,kBAAkB;QAClB,qBAAqB;QACrB,kBAAkB;QAClB,UAAU;IACd;;IAEA;QACI,UAAU;IACd;AACJ","sourcesContent":["@layer packages {\n    .maxValueInput :global(:root) {\n        --input-width: 100%;\n    }\n\n    .maxValueInput {\n        display: flex;\n        width: 100%;\n        gap: calc(var(--globals-baseUnit) * 3);\n    }\n\n    .maxValueInputRange {\n        flex: 1 1 auto;\n        display: flex;\n        align-items: center;\n        padding: 0;\n    }\n\n    .maxValueInputRangeDisabled {\n        opacity: 0.4;\n    }\n\n    .maxValueInputField {\n        flex: 0 0 120px;\n        position: relative;\n    }\n\n    .maxValueInputWrapper {\n        justify-content: center;\n        flex: 0 0 auto;\n    }\n\n    .maxValueInputCore {\n        text-align: right;\n        width: var(--input-width);\n        box-sizing: content-box;\n    }\n\n    .maxValueInputCoreRightElement {\n        margin-left: 0;\n    }\n\n    .maxValueInputDisabledStringElement {\n        color: var(--colors-gray-700);\n    }\n\n    .maxValueInputHiddenSpan {\n        visibility: hidden;\n        display: inline-block;\n        position: absolute;\n        z-index: 0;\n    }\n\n    .maxValueInputPopover {\n        z-index: 2;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export var maxValueInput = `ZjXKsurEv6g61GyfEUTH`;
export var maxValueInputRange = `HkOOMY6VkJ3P8vMjycLk`;
export var maxValueInputRangeDisabled = `RqzfrPCdp3k7V6KuikTR`;
export var maxValueInputField = `Y4F4rQG_n9UlDMXopZxN`;
export var maxValueInputWrapper = `kmbhFcdniqoFSDZvfcob`;
export var maxValueInputCore = `oVjoITkfsGTS9NuuT_e7`;
export var maxValueInputCoreRightElement = `uzfK667bcBf6P5ZZAcOQ`;
export var maxValueInputDisabledStringElement = `NUBH0ecMTEsXCtpUvAww`;
export var maxValueInputHiddenSpan = `cT3d8sQm2HcAjbOdBvKj`;
export var maxValueInputPopover = `BOkjqtsPmh0Wa2iYzGF0`;
export default ___CSS_LOADER_EXPORT___;
