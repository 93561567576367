import React, {CSSProperties, FC} from "react";
import classNames from "classnames";

import {TextVariant, TextVariantUnionType} from "../utils/text_variant";

import {
    BODY_COPY_0,
    BODY_COPY_1,
    BODY_COPY_2,
    BUTTON_BIG,
    BUTTON_MEDIUM,
    BUTTON_MICRO,
    BUTTON_SMALL,
    HEADLINE_0,
    HEADLINE_1,
    HEADLINE_2,
    HEADLINE_3,
    HEADLINE_4,
    HEADLINE_5,
    HEADLINE_6,
    INFO_TXT_1,
    INFO_TXT_2,
    INFO_TXT_3,
    MINI_HEADER,
    noWrapStyle,
    strongStyle,
    textBase,
    textBasePrimary,
    textBaseSecondary
} from "./Text.module.css";

const defaultVariantMapping: Record<TextVariantUnionType, React.ElementType> = {
    [TextVariant.HEADLINE_0]: "h1",
    [TextVariant.HEADLINE_1]: "h1",
    [TextVariant.HEADLINE_2]: "h2",
    [TextVariant.HEADLINE_3]: "h3",
    [TextVariant.HEADLINE_4]: "h4",
    [TextVariant.HEADLINE_5]: "h5",
    [TextVariant.HEADLINE_6]: "h6",
    [TextVariant.BODY_COPY_0]: "p",
    [TextVariant.BODY_COPY_1]: "p",
    [TextVariant.BODY_COPY_2]: "p",
    [TextVariant.INFO_TXT_1]: "p",
    [TextVariant.INFO_TXT_2]: "p",
    [TextVariant.INFO_TXT_3]: "p",
    [TextVariant.MINI_HEADER]: "span",
    [TextVariant.BUTTON_BIG]: "span",
    [TextVariant.BUTTON_SMALL]: "span",
    [TextVariant.BUTTON_MEDIUM]: "span",
    [TextVariant.BUTTON_MICRO]: "span"
};

type ITextMargin = "0.4rem" | "0.8rem" | "1.2rem" | "1.6rem" | "2.4rem" | "2.8rem" | "3.2rem" | "4.8rem" | "6.4rem";

export interface ITextProps {
    children?: React.ReactNode;
    as?: React.ElementType;
    variant?: TextVariantUnionType;
    noWrap?: boolean;
    strong?: boolean;
    color?: string;
    align?: "start" | "center" | "inherit" | "justify" | "left" | "right";
    mb?: ITextMargin;
    fontFamily?: "primary" | "secondary";
    className?: string;
    id?: string;
    onClick?: (e: React.MouseEvent) => void;
    style?: CSSProperties;
    dataTestId?: string;
}

export const Text: FC<ITextProps> = (props) => {
    const {as, variant = TextVariant.BODY_COPY_0, noWrap = false, strong = false, fontFamily = "primary", color, align, mb, style = {}} = props;
    const DomElement = as || (variant && defaultVariantMapping[variant]) || "span";

    const variantStyle = getTextVariantStyleFor(variant);

    const fontFamilyClass = classNames(
        variantStyle,
        fontFamily === "primary" ? textBasePrimary : textBaseSecondary,
        strong && strongStyle,
        noWrap && noWrapStyle,
        textBase,
        props.className
    );

    const textColor = color ? {"--text-local-color": color} : {};
    const textAlign = align ? {"--text-local-align": align} : {};
    const marginBottom = mb ? {"--mb-local": mb} : {};

    return (
        <DomElement
            style={{...textColor, ...textAlign, ...marginBottom, ...style}}
            className={fontFamilyClass}
            onClick={props.onClick}
            id={props.id}
            data-testid={props.dataTestId}
        >
            {props.children}
        </DomElement>
    );
};

/**
 * return classname to style text outside of Text component
 * @param variant
 */
export const getTextVariantStyleFor = (variant: TextVariantUnionType): string => {
    switch (variant) {
        case TextVariant.HEADLINE_0: {
            return HEADLINE_0;
        }
        case TextVariant.HEADLINE_1: {
            return HEADLINE_1;
        }
        case TextVariant.HEADLINE_2: {
            return HEADLINE_2;
        }
        case TextVariant.HEADLINE_3: {
            return HEADLINE_3;
        }
        case TextVariant.HEADLINE_4: {
            return HEADLINE_4;
        }
        case TextVariant.HEADLINE_5: {
            return HEADLINE_5;
        }
        case TextVariant.HEADLINE_6: {
            return HEADLINE_6;
        }
        case TextVariant.BODY_COPY_0: {
            return BODY_COPY_0;
        }
        case TextVariant.BODY_COPY_1: {
            return BODY_COPY_1;
        }
        case TextVariant.BODY_COPY_2: {
            return BODY_COPY_2;
        }
        case TextVariant.INFO_TXT_1: {
            return INFO_TXT_1;
        }
        case TextVariant.INFO_TXT_2: {
            return INFO_TXT_2;
        }
        case TextVariant.INFO_TXT_3: {
            return INFO_TXT_3;
        }
        case TextVariant.MINI_HEADER: {
            return MINI_HEADER;
        }
        case TextVariant.BUTTON_BIG: {
            return BUTTON_BIG;
        }
        case TextVariant.BUTTON_MEDIUM: {
            return BUTTON_MEDIUM;
        }
        case TextVariant.BUTTON_SMALL: {
            return BUTTON_SMALL;
        }
        case TextVariant.BUTTON_MICRO: {
            return BUTTON_MICRO;
        }
    }
};
