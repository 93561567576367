import React, {useEffect} from "react";

interface IProps {
    lockBodyScroll?: boolean;
}

export const ModalGlobalStyles = (props: IProps) => {
    useEffect(() => {
        document.body.style.overflow = props.lockBodyScroll ? "hidden" : "auto";

        return () => {
            document.body.style.overflow = "auto";
        };
    }, [props.lockBodyScroll]);

    return null;
};
