import type {ReactNode, RefObject} from "react";
import {useEffect, useState} from "react";
import {autoPlacement, offset, useClientPoint, useFloating, useInteractions} from "@floating-ui/react";

import * as styles from "./CursorPopup.module.css";

interface IProps {
    forceOpen?: boolean;
    popupContent: ReactNode;
}

interface IPropsWithReference extends IProps {
    referenceElement: RefObject<HTMLElement>;
    children?: never;
}

interface IPropsWithChildren extends IProps {
    children: ReactNode;
    referenceElement?: never;
}

const middleware = [autoPlacement(), offset(20)];

export const CursorPopup = (props: IPropsWithReference | IPropsWithChildren) => {
    const {referenceElement: externalReference, forceOpen} = props;

    const [isOpenInternal, setIsOpenInternal] = useState(false);
    const isOpen = forceOpen || isOpenInternal;

    const {refs, floatingStyles, context} = useFloating({
        open: isOpen,
        middleware
    });
    const clientPoint = useClientPoint(context);
    const {getFloatingProps} = useInteractions([clientPoint]);

    const referenceElement = externalReference || refs.domReference;

    useEffect(() => {
        if (!forceOpen) {
            referenceElement.current?.addEventListener("mouseenter", () => setIsOpenInternal(true));
            referenceElement.current?.addEventListener("mouseleave", () => setIsOpenInternal(false));
        }

        return () => {
            if (!forceOpen) {
                referenceElement.current?.removeEventListener("mouseenter", () => setIsOpenInternal(true));
                referenceElement.current?.removeEventListener("mouseleave", () => setIsOpenInternal(false));
            }
        };
    }, [forceOpen]);

    return (
        <>
            {props.children && (
                <span ref={refs.setReference} className={styles.cursorRef}>
                    {props.children}
                </span>
            )}
            {isOpen && (
                <div ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()} className={styles.cursorPopup}>
                    {props.popupContent}
                </div>
            )}
        </>
    );
};
