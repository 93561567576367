// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@layer packages {
    .UzHpvqEcnNPNpkrGp36K {
        /*hack: Sometimes the popup is rendered before useFloating transition is applied and is displayed in wrong spot for one+ frame. Fade animation is added to prevent this.*/
        animation: f7Mo7tf62Y2APxMqX6_m 0.2s ease-in;
    }

    @keyframes f7Mo7tf62Y2APxMqX6_m {
        0% {
            opacity: 0;
        }
        30% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .kTQl_vTVPTWnbm0abb20 {
        display: inline-block;
    }
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/core/floating/src/components/CursorPopup.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,yKAAyK;QACzK,4CAA8B;IAClC;;IAEA;QACI;YACI,UAAU;QACd;QACA;YACI,UAAU;QACd;QACA;YACI,UAAU;QACd;IACJ;;IAEA;QACI,qBAAqB;IACzB;AACJ","sourcesContent":["@layer packages {\n    .cursorPopup {\n        /*hack: Sometimes the popup is rendered before useFloating transition is applied and is displayed in wrong spot for one+ frame. Fade animation is added to prevent this.*/\n        animation: fadein 0.2s ease-in;\n    }\n\n    @keyframes fadein {\n        0% {\n            opacity: 0;\n        }\n        30% {\n            opacity: 0;\n        }\n        100% {\n            opacity: 1;\n        }\n    }\n\n    .cursorRef {\n        display: inline-block;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export var cursorPopup = `UzHpvqEcnNPNpkrGp36K`;
export var fadein = `f7Mo7tf62Y2APxMqX6_m`;
export var cursorRef = `kTQl_vTVPTWnbm0abb20`;
export default ___CSS_LOADER_EXPORT___;
