// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@layer packages {
    .uvgyaJVSYamMQAQKT5kt {
        &:focus-visible {
            outline: none;
        }
    }
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/core/floating/src/components/ManualPopover.module.css"],"names":[],"mappings":"AAAA;IACI;QACI;YACI,aAAa;QACjB;IACJ;AACJ","sourcesContent":["@layer packages {\n    .manualPopoverFloating {\n        &:focus-visible {\n            outline: none;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export var manualPopoverFloating = `uvgyaJVSYamMQAQKT5kt`;
export default ___CSS_LOADER_EXPORT___;
