// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@layer packages {
    .Scta1s5XCh3dioJW3jQn {
        color: var(--colors-secondary);
        opacity: 1;
        border: 0;
        background: transparent;
        width: 100%;
        padding: 1px 2px; /* normalize to chrome styles */

        outline: 0;
    }

    .Scta1s5XCh3dioJW3jQn::placeholder {
        color: var(--colors-gray-700);
    }

    .Scta1s5XCh3dioJW3jQn:focus {
        outline: 0;
    }

    .Scta1s5XCh3dioJW3jQn[type="file"]::file-selector-button {
        right: 3.6rem;
        position: absolute;
        background-color: transparent;
        text-transform: uppercase;
        font-size: 1.4rem;
        font-weight: 600;
        font-family: var(--globals-text-family);
        border: none;
    }

    .Scta1s5XCh3dioJW3jQn[type="file"]::file-selector-button:before {
        content: "\$\$\$\$\$\$\$\$\$\$\$";
        position: absolute;
    }

    .YM5uBWUJ9y52LEWFxpuo {
        resize: none;
    }

    .Ikx1dEQd_KQer__l1kEP {
        color: var(--colors-gray-700);
    }
}
`, "",{"version":3,"sources":["webpack://./../../packages/design_system_modules/forms/inputs/src/components/input/InputCore.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,8BAA8B;QAC9B,UAAU;QACV,SAAS;QACT,uBAAuB;QACvB,WAAW;QACX,gBAAgB,EAAE,+BAA+B;;QAEjD,UAAU;IACd;;IAEA;QACI,6BAA6B;IACjC;;IAEA;QACI,UAAU;IACd;;IAEA;QACI,aAAa;QACb,kBAAkB;QAClB,6BAA6B;QAC7B,yBAAyB;QACzB,iBAAiB;QACjB,gBAAgB;QAChB,uCAAuC;QACvC,YAAY;IAChB;;IAEA;QACI,sBAAsB;QACtB,kBAAkB;IACtB;;IAEA;QACI,YAAY;IAChB;;IAEA;QACI,6BAA6B;IACjC;AACJ","sourcesContent":["@layer packages {\n    .inputStyle {\n        color: var(--colors-secondary);\n        opacity: 1;\n        border: 0;\n        background: transparent;\n        width: 100%;\n        padding: 1px 2px; /* normalize to chrome styles */\n\n        outline: 0;\n    }\n\n    .inputStyle::placeholder {\n        color: var(--colors-gray-700);\n    }\n\n    .inputStyle:focus {\n        outline: 0;\n    }\n\n    .inputStyle[type=\"file\"]::file-selector-button {\n        right: 3.6rem;\n        position: absolute;\n        background-color: transparent;\n        text-transform: uppercase;\n        font-size: 1.4rem;\n        font-weight: 600;\n        font-family: var(--globals-text-family);\n        border: none;\n    }\n\n    .inputStyle[type=\"file\"]::file-selector-button:before {\n        content: \"$$$$$$$$$$$\";\n        position: absolute;\n    }\n\n    .multiline {\n        resize: none;\n    }\n\n    .disabled {\n        color: var(--colors-gray-700);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export var inputStyle = `Scta1s5XCh3dioJW3jQn`;
export var multiline = `YM5uBWUJ9y52LEWFxpuo`;
export var disabled = `Ikx1dEQd_KQer__l1kEP`;
export default ___CSS_LOADER_EXPORT___;
